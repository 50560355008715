
import {Component, Prop, Vue} from 'vue-property-decorator';
import IDashboardFilter from '@/types/IDashboardFilter';
import DateRangePicker from '@/components/DateRangePicker.vue';
import CollapseMultiSelect from '@/components/CollapseMultiSelect.vue';
import {arrayLength, required} from '@/utils/validationRules';

@Component({
  components: {
    CollapseMultiSelect,
    DateRangePicker,
  },
})
export default class DashboardAnalysisFilters extends Vue {
  @Prop()
  filters!: IDashboardFilter;

  @Prop()
  keys!: string[];

  @Prop()
  keysSelectLabel!: string;

  @Prop({default: false})
  collapseKeysSelect!: boolean;

  rules = {
    dateRange: [required, this.dateRangeValidator],
  };

  get dateRange(): (string | null)[] {
    if (this.filters.dateTo) {
      return [this.filters.dateFrom, this.filters.dateTo];
    } else if (this.filters.dateFrom) {
      return [this.filters.dateFrom];
    } else {
      return [];
    }
  }

  set dateRange(dateRange: (string | null)[]) {
    if (dateRange[0] && dateRange[1] && dateRange[1] < dateRange[0]) {
      this.filters.dateFrom = dateRange[1];
      this.filters.dateTo = dateRange[0];
    } else {
      this.filters.dateFrom = dateRange[0];
      this.filters.dateTo = dateRange[1];
    }
  }

  dateRangeValidator(value: string) {
    return arrayLength(value ? value.split(' - ') : [], 2) === true || this.$t('SYSTEM_END_DATE_NEEDS_TO_BE_PROVIDED');
  }

  changed() {
    Object.keys(this.filters).forEach((key) => {
      // @ts-ignore
      this.filters[key] = this.filters[key] || '';
    });
    if (this.filters.dateFrom && this.filters.dateTo) {
      this.$emit('changed', this.filters);
    }
  }
}
